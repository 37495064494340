<template>
  <v-form>
    <v-text-field
        v-model="$v.form.year.$model"
        label="Год"
        type="number" 
        :color="$const.color.primary"
    ></v-text-field>

    <v-select
      v-model="$v.form.subject.$model"
      :error-messages="getErrors('form.subject')"
      required
      :items="subjects"
      label="Предмет"
    />

    <v-select
      v-model="$v.form.grade.$model"
      :disabled="!$v.form.subject.$model"
      :error-messages="getErrors('form.grade')"
      required
      :items="grades"
      label="Класс"
    />
    
    <v-select
        v-model="$v.form.category.$model"
        :error-messages="getErrors('form.category')"
        label="Категория"
        :items="categories"
        :color="$const.color.primary" 
    />

    <v-select
        v-if="form.category === 'control'"
        v-model="$v.form.collection.$model"
        :error-messages="getErrors('form.collection')"
        required
        :items="collections"
        label="Коллекция"
        name="collection"
    ></v-select>
    
    <template v-if="serverErrors && serverErrors.summary" >
      <v-alert dense type="error">
        {{ serverErrors.summary }}
      </v-alert>
    </template>

    <v-divider class="my-4" />
    <v-btn
        outlined
        :disabled="!canRequestTasksScoresReport"
        :loading="loading"
        @click="submit"
    >Скачать</v-btn>
  </v-form>
</template>

<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ApiHelper from '@/helpers/ApiHelper'
import { JOB_SERVER, JOB_SERVER_API_KEY } from '@/servers'
import subjectsDictionary from '@/subjects'

export default {
    name: 'TaskScoresReportForm',
    props: {
        model: { type: Object }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            categories: [{value: 'test', text: 'ВПР'}, {value: 'control', text: 'Контрольная работа'}],
            form: {
                year: (new Date()).getFullYear(),
                subject: '',
                grade: 4,
                category: 'test',
                collection: 1
            },
            loading: false
        };
    },
    validations() {
        return {
            form: {
                year: { required },
                subject: { required },
                grade: { required },
                category: { required },
                collection: { required }
            }
        }
    },
    watch: {
        'form.category'() {
            if (this.form.category === 'test')
                this.form.collection = 1
        }
    },
    computed: {
        subjects () {
            return subjectsDictionary.map(item => ({ value: item.value, text: item.text }))
        },
        grades () {
            if (!this.form.subject) { return [] }
            return subjectsDictionary.find(item => item.value === this.form.subject)?.grades.flat()
        },
        canRequestTasksScoresReport () {
            return  this.form.grade &&
                    this.form.subject &&
                    this.form.year &&
                    parseInt(this.form.grade) > 0
        },
        collections () {
            return (new Array(10)).fill(null).map((item, index) => index + 1)
        }
    },
    methods: {
        async submit () {
            if (this.loading) { return false; }

            if (!this.validate()) {
                console.log('Validation failed');
                return false;
            }
            
            try {
                this.loading = true;
                const response = await ApiHelper.request(`${JOB_SERVER}/api/report/tasks-scores-list`, 'get', {}, this.form, { api_key: JOB_SERVER_API_KEY, responseType: 'blob' });

                if (response.type !== 'text/csv') { throw new Error('Wrong response type.') }
                
                let exportUrl = URL.createObjectURL(response);
                window.location.assign(exportUrl);
                URL.revokeObjectURL(exportUrl);
            } catch (e) {
                console.error(e)
                alert('Ошибка формирования файла.')
            }
            finally {
                this.loading = false;
                this.$emit('success', 1);
            }
        }
    }
}
</script>
