<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Выгрузка оценок"
        url-back="/task"
    >
        <task-scores-report-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import TaskScoresReportForm from '@/components/forms/TaskScoresReportForm.vue'
export default {
    components: { FormView, TaskScoresReportForm }
}
</script>